<template>
<div>
        <h4>Lesson</h4>

        <p>
            We have already covered a lot of ground. Most of the important concepts are over and only few minor ones remain. If you are interested in covering those as well then you can skim through the <a href="https://github.com/ziishaned/learn-regex#learn-regex">github page</a> on which the lessons are based.
            </p>

    </div>
</template>
